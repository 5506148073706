<template>
  <div class="earnings">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="moreOnLoad"
    >
      <earnings-top :balance="balance"></earnings-top>
      <referral></referral>
      <div class="collectionAndDeposit">
        <van-row
          type="flex"
          align="center"
          justify="space-around"
          class="titleList"
        >
          <van-col class="collectionLog" @click="changeListType(1)">
            <p
              :class="[
                'collectionTitle',
                type === 1 ? 'collectionTitleAction' : '',
              ]"
            >
              {{ $t.earningsInfo.incomeRecords }}
            </p>
            <div
              :class="[
                'collectionBottom',
                type !== 1 ? 'collectionBottomAction' : '',
              ]"
            ></div>
          </van-col>
          <van-col class="collectionLog" @click="changeListType(-1)">
            <p
              :class="[
                'collectionTitle',
                type !== 1 ? 'collectionTitleAction' : '',
              ]"
            >
              {{ $t.earningsInfo.withdrawRecords }}
            </p>
            <div
              :class="[
                'collectionBottom',
                type === 1 ? 'collectionBottomAction' : '',
              ]"
            ></div>
          </van-col>
        </van-row>
        <div class="lists">
          <div class="LollectionList">
            <div class="Listbox" v-show="type === 1">
              <van-row
                class="list"
                type="flex"
                align="center"
                v-for="(item, index) in incomeList"
                :key="index"
              >
                <Income :item="item" />
              </van-row>
            </div>
            <div class="Listbox" v-show="type !== 1">
              <van-row
                class="list"
                type="flex"
                align="center"
                v-for="(item, index) in tackList"
                :key="index"
              >
                <Tack :item="item" />
              </van-row>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import EarningsTop from "./components/EarningsTop";
import Referral from "./components/Referral";
import Income from "./components/Income";
import Tack from "./components/Tack";
// import CollectionAndDeposit from "./components/CollectionAndDeposit";
import { List } from "vant";

export default {
  name: "Earnings",
  components: {
    EarningsTop,
    Referral,
    Income,
    Tack,
    "van-list": List,
    // CollectionAndDeposit
  },
  data() {
    return {
      data: "",
      pageIndex: 1,
      incomeIndex: 1,
      tackIndex: 1,
      loading: true,
      finished: false,
      isPlaceholder: false,
      earnDataList: [], //资产
      balance: 0.0, //余额
      type: 1,
      incomeList: [], //收益
      tackList: [], //支出
      incomeIsData: true, //收益是否有数据
      tackIsData: true, //提现是否有数据
    };
  },
  mounted() {
    this.getLoadShopWallet();
  },
  methods: {
    changeListType(type) {
      this.type = type;
      // this.incomeIndex = 1;
      // this.tackIndex = 1;
      this.finished = false;
      if (type == "1") {
        !this.incomeIsData && (this.finished = true);
      } else {
        !this.tackIsData && (this.finished = true);
      }
      // this.finished = false;
      // this.incomeIsData = true; //收益是否有数据
      // this.tackIsData = true; //提现是否有数据
    },
    // 获取搜索页数据
    getLoadShopWallet() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        PageIndex: 1,
      };
      this.$api.user
        .loadShopWallet(param)
        .then((res) => {
          this.balance = res.data.Balance;
          this.incomeList = res.data.Income;
          this.tackList = res.data.Tack;
          //incomeIsData: true, //收益是否有数据
          //tackIsData: true //提现是否有数据
          if (this.incomeList.length == 0 || this.tackList.length == 0)
            this.finished = true;

          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.isfirst = false;
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    },
    //加载更多
    moreOnLoad() {
      let pageIndex = 1;
      //incomeIndex: 1,
      //tackIndex: 1,
      if (this.type == "1") {
        pageIndex = this.incomeIndex + 1;
        if (this.incomeIsData === false) return;
      } else {
        pageIndex = this.tackIndex + 1;
        if (this.tackIsData === false) return;
      }
      let param = {
        PageIndex: pageIndex,
        Type: this.type,
      };
      this.$api.user
        .loadShopWalletInfoByPage(param)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.loading = false;
            // incomeList: [], //收益
            // tackList: [] //支出
            //incomeIsData: true, //收益是否有数据
            //tackIsData: true //提现是否有数据
            if (this.type == "1") {
              this.incomeList = this.incomeList.concat(res.data);
              this.incomeIndex = pageIndex;
            } else {
              this.tackList = this.incomeList.concat(res.data);
              this.tackIndex = pageIndex;
            }
          } else {
            //
            if (this.type == "1") {
              this.incomeIsData = false;
            } else {
              this.tackIsData = false;
            }
            this.finished = true;
            this.loading = false;
            // this.incomeIsData && this.tackIsData && (this.finished = true);
          }
        })
        .catch((error) => {
          this.isfirst = false;
          // this.loading = true;
          this.loading = false;
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.earnings {
  width: 100%;
  padding-bottom: 30px;
  .collectionAndDeposit {
    width: 92%;
    margin: 0 auto;
    font-size: 14px;
    border-radius: 15px;
    box-shadow: 0 0 0px 1px #ece7e7;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #ffffff;
    // padding: 30px 38px;
    margin-bottom: 15px;
    .titleList {
      padding-top: 15px;
      margin-top: 15px;
      .collectionLog {
        .collectionTitle {
          margin: 0;
          padding: 0;
          font-size: 14px;
          color: #333333;
          font-weight: bold;
        }
        .collectionTitleAction {
          color: #43cd9a;
        }
        .collectionBottom {
          height: 4px;
          background-color: #43cd9a;
          border-radius: 20px;
          width: 30px;
          margin: 10px auto 5px;
        }
        .collectionBottomAction {
          opacity: 0;
        }
      }
    }
    .lists {
      width: 100%;
      .LollectionList {
        width: 100%;
        .list {
          width: 100%;
          margin: 0 auto;
          padding: 20px 0;
          border-top: 0.02rem solid #ece7e7;
        }
      }
    }
  }
}
</style>
