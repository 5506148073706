<template>
  <div class="referral">
    <div class="plan">
      <div class="planBox">
        <div class="border"></div>
        <van-row type="flex" align="center" class="iconList">
          <van-col class="itemIcon" v-for="item in iconList" :key="item.id">
            <img
              class="icon"
              src="~assets/img/earnings/icon_earnings_circle@2x.png"
            />
          </van-col>
        </van-row>
      </div>
      <van-row type="flex" align="center" class="titleList">
        <van-col class="itemTitle" v-for="item in titleList" :key="item.id">{{
          item.name
        }}</van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "Referral",
  data() {
    return {
      iconList: [{ id: 0 }, { id: 1 }, { id: 2 }],
      titleList: [
        { id: 0, name: this.$t.earningsInfo.orderIncomes },
        { id: 1, name: this.$t.earningsInfo.paymentDeclaration },
        { id: 2, name: this.$t.earningsInfo.paymentWithdrew },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.referral {
  width: 92%;
  margin: 0 auto;
  font-size: 14px;
  border-radius: 15px;
  box-shadow: 0 0 0px 1px #ece7e7;
  position: relative;
  background-color: #ffffff;
  margin-top: 15px;
  .plan {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    .planBox {
      width: 100%;
      position: absolute;
      top: 15px;
      .iconList {
        width: 100%;
        text-align: center;
        position: relative;
        top: 15px;
        .itemIcon {
          width: 33.33% !important;
          text-align: center;
          .icon {
            width: 20px;
          }
        }
      }
      .border {
        border-bottom: 0.03rem solid #43cd9a;
        width: 68%;
        margin-left: 15%;
        position: absolute;
        top: 25px;
      }
    }
    .titleList {
      color: #000000;
      width: 100%;
      text-align: center;
      position: relative;
      .itemTitle {
        margin-top: 40px;
        padding: 0 0.2rem;
        width: 33.33% !important;
        text-align: center;
        font-size: 12px;
        color: #000000;
      }
    }
  }
  .state {
    padding: 0.5rem 0;
    width: 90%;
    margin: auto;
    text-align: center;
    width: 100%;
    font-size: 22px;
    color: #a7a7a7;
    text-align: center;
    .skuInfo {
      width: 90%;
      display: inline-block;
    }
  }
}
</style>
