var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"earnings"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.$t.noMoreData},on:{"load":_vm.moreOnLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('earnings-top',{attrs:{"balance":_vm.balance}}),_c('referral'),_c('div',{staticClass:"collectionAndDeposit"},[_c('van-row',{staticClass:"titleList",attrs:{"type":"flex","align":"center","justify":"space-around"}},[_c('van-col',{staticClass:"collectionLog",on:{"click":function($event){return _vm.changeListType(1)}}},[_c('p',{class:[
              'collectionTitle',
              _vm.type === 1 ? 'collectionTitleAction' : '',
            ]},[_vm._v(" "+_vm._s(_vm.$t.earningsInfo.incomeRecords)+" ")]),_c('div',{class:[
              'collectionBottom',
              _vm.type !== 1 ? 'collectionBottomAction' : '',
            ]})]),_c('van-col',{staticClass:"collectionLog",on:{"click":function($event){return _vm.changeListType(-1)}}},[_c('p',{class:[
              'collectionTitle',
              _vm.type !== 1 ? 'collectionTitleAction' : '',
            ]},[_vm._v(" "+_vm._s(_vm.$t.earningsInfo.withdrawRecords)+" ")]),_c('div',{class:[
              'collectionBottom',
              _vm.type === 1 ? 'collectionBottomAction' : '',
            ]})])],1),_c('div',{staticClass:"lists"},[_c('div',{staticClass:"LollectionList"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 1),expression:"type === 1"}],staticClass:"Listbox"},_vm._l((_vm.incomeList),function(item,index){return _c('van-row',{key:index,staticClass:"list",attrs:{"type":"flex","align":"center"}},[_c('Income',{attrs:{"item":item}})],1)}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type !== 1),expression:"type !== 1"}],staticClass:"Listbox"},_vm._l((_vm.tackList),function(item,index){return _c('van-row',{key:index,staticClass:"list",attrs:{"type":"flex","align":"center"}},[_c('Tack',{attrs:{"item":item}})],1)}),1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }